.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'Select file';
  display: inline-block;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 10pt;
  text-align: center;
}

.selling-price {
  margin-left: 7px;
}

.pagination {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  list-style: none;
  border-radius: 0.375rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-size: 0.875rem;
}

.pagination li {
  margin-left: 25px;
}

.pagination li a {
  text-decoration: none;
}

.product-card-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  place-items: center;
  grid-gap: 20px;
  margin: 20px 0px;
}
.product-card {
  height: 300px;
  width: 275px;
  margin: 10px;
  padding: 15px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 5px 5px 10px #e0e0e0, -5px -5px 10px #ffffff;
  border: rgba(204, 204, 204, 0.486) solid 1px;
  transition: all 0.25s ease-in-out;
}

.product-card:hover {
  box-shadow: 7px 6px 25px #c4c4c4, -8px -7px 10px #ffffff;
}

.product-card-image {
  height: 200px;
  transition: all 0.5s ease-in-out;
}
.product-card-image-div {
  display: grid;
  place-items: center;
  overflow: hidden;
}
.product-card-details {
  text-align: center;
  color: rgb(40, 38, 43);
  font-size: 14px;
}

.product-card:hover .product-card-image {
  transform: scale(1.2) translateY(-10px);
}

.custom-file-input {
  width: 140px;
  height: 40px;
  overflow: hidden;
  background-color: #2065d1;
  color: #ffffff;
  padding: 10px 5px;
  border-radius: 10px;
  display: grid;
  place-items: center;
  box-shadow: 5px 7px 10px 2px #2064d148;
  transition: all 0.2s linear;
}
.custom-file-input:hover {
  background-color: #103996;
}

.img-upload-pre {
  height: 150px;
  width: 160px;
  border-radius: 10%;
  background-size: cover;
  background-position: center;
  box-shadow: 5px 3px 20px 1px #2064d148;
}
.img-order {
  height: 140px;
  width: 140px;
  border-radius: 10%;
  background-size: cover;
  background-position: center;
  box-shadow: 5px 3px 20px 1px #2064d148;
}
.img-product {
  height: 80px;
  width: 80px;
  border-radius: 10%;
  background-size: cover;
  background-position: center;
  box-shadow: 5px 3px 20px 1px #2064d148;
  background-color: #ffd90199;
}
.product-data {
  display: flex;
  padding: 6px;
  flex-direction: column;
}
.product-img {
  height: 80px;
  width: 80px;
}
.product-data-font {
  font-size: 14px;
  padding: 10px;
  margin: 4px;
  display: flex;
  align-items: center;
}
.order-product-img {
  height: 75px;
  margin: 5px;
  margin-right: 15px;
}
.brand-buttons {
  margin: 10px;
  width: 150px;
  float: right;
}

.img-upload-product {
  height: 230px;
  width: 230px;
  border-radius: 5%;
  background-size: cover;
  background-position: center;
  box-shadow: 5px 3px 20px 1px #2064d148;
}
.img-logo {
  height: 90px;
  width: 280px;
  border-radius: 1%;
  background-size: cover;
  background-position: center;
}

/* .button-sec {
  position: fixed;
  top: 70px;
  right: 15px;
} */

.bg-waring {
  background-color: #ffa20049;
}

.bg-waring-2 {
  background-color: #ff7700ba;
}

.cke_editable {
  /* background-color: rgb(220, 236, 255); */
  min-height: 200px;
  padding: 15px;
  border-radius: 15px;
  border: #c4c4c4 solid 1px !important;
}
.file-dropzone {
  background-color: #e5e5e525;
  height: 150px !important;
  width: 600px !important;
}
.file-dropzone-wrap {
  display: grid;
  place-items: center;
  background-color: #ffffff;
  height: 150px !important;
  border: #c4c4c4 dashed 1px !important;
}

.img-prewview-upload {
  height: 150px;
  width: 150px;
  border-radius: 10%;
  background-size: cover;
  background-position: center;
  box-shadow: 5px 3px 20px 1px #2064d148;
}
